<style lang="scss">
.futter {
  [class*='col-'] {
    /*justify-items: center;
    align-self: center;*/
  }
  h2 {
    text-transform: uppercase;
  }
  .futter-box {
    border: 0px solid red;
    margin-top: 50px;
  }
  .logo-box {
    padding: 100px 0;
    text-align: center;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, #e9546f 33%, #fff 0%) bottom/7px 1px repeat-x;
      position: absolute;
      left: 0;
      top: 49%;
      z-index: 1;
    }
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, #000 33%, #fff 0%) bottom/7px 1px repeat-x;
      background-position: center center;
      position: absolute;
      left: 0;
      top: 51%;
      z-index: 1;
    }
    .img-box {
      background: #fff;
      padding: 10px 20px;
      max-width: 200px;
      display: inline-block;
      position: relative;
      z-index: 2;
      img {
        width: 100%;
        display: inline-block;
        opacity: 0.5;
        position: relative;
        z-index: 2;
      }
    }
  }
  .tab-group-container {
    .title.active {
      border-bottom: 3px solid $futter-red;
      background: #000;
      color: #fff;
    }
    .title-container {
      @media (max-width: 768px) {
        display: block !important;
      }
    }
    .title {
      padding: 15px 20px;
      @media (max-width: 768px) {
        display: block;
        width: 100%;
        border-bottom: 3px solid #eee;
        text-align: center;
      }
    }
    .inner-content {
      [class*='col-'] {
        justify-items: center;
        align-self: center;
      }
      padding: 15px;
      img {
        display: block;
        margin: 0 auto;
      }
      .leistung {
        &:before {
          content: '';
          display: block;
          margin: 20px 0;
          width: 150px;
          height: 5px;
          background: $futter-red;
        }
        &:after {
          content: '';
          display: block;
          margin: 20px 0;
          width: 100px;
          height: 5px;
          background: $futter-red;
        }
        strong {
          display: block;
          font-size: 140%;
          text-transform: uppercase;
        }
        span {
          display: block;
          margin-top: 5px;
        }
        .preis {
          display: block;
          background: $futter-red;
          color: #fff;
          padding: 10px 15px;
          display: inline-block;
          margin-top: 10px;
        }
      }
      .bsp {
        margin-top: 30px;

        .bsp-headline {
          position: relative;
          text-align: center;
          margin-bottom: 20px;
          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background: #000;
            position: absolute;
            top: 50%;
          }
          span {
            display: inline-block;
            padding: 10px 15px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 90%;
            background: #fff;
            position: relative;
            z-index: 2;
          }
        }
        a {
          display: block;

          text-align: center;
          span {
            display: block;
            padding: 10px 15px;
            background: $futter-red;
            color: #fff;
          }

          img {
            padding: 15px;
          }
          &:hover {
            opacity: 0.7;
            transform: scale(0.95);
          }
        }
      }
    }
  }
  .werbepaket {
    .col-lg-4 {
      .werbebox {
        background: #eee;
        padding: 15px;
        @media (max-width: 992px) {
          margin-bottom: 15px;
        }
        .headline {
          font-weight: 300;
          font-size: 120%;
          text-align: left;
          strong {
            display: block;
            font-size: 140%;
            text-transform: uppercase;
          }
        }
        p {
          padding: 10px 0px;
        }
      }
    }
    .btn-pdf {
      margin-top: 20px;
      a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 15px 15px;
        background: $futter-red;
        color: #fff !important;
        position: relative;

        margin: 0 auto;
        &:hover {
          opacity: 0.7;
        }
        span {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>

<template>
  <div class="content futter container">
    <h1>Futter</h1>

    <div class="futter-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
          <div class="bild-box"><img src="@/assets/img/img-content/sonderprodukte/futter-v1.jpg" alt="Was ist Futter?" class="img-fluid shadow" /></div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Was ist Futter?</h3>
          <p>Futter ist ein freches, digitales Qualitätsmedium für junge Menschen zwischen 18 und 34 Jahren, das unterhält, inspiriert und, wo es sein muss, auch kritisch berichtet. Das Futter-Team macht Wünsche &amp; Probleme der Zielgruppe zum Thema und ist direkter Ansprechpartner in jeder Lebenslage.</p>
        </div>
      </div>
    </div>
    <div class="futter-box">
      <div class="row">
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box">
            <img src="@/assets/img/img-content/sonderprodukte/futter-v2.jpg" alt="Wen erreicht man mit Futter?" class="img-fluid shadow" />
          </div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Wen erreicht man mit Futter?</h3>
          <p>Unsere Community besteht aus überwiegend Digital Natives im Alter von 18 bis 34 Jahren, die aus Graz sind. Ebenso erreichen wir teilweise Wiener und Kärntner in diesem Alter.</p>
        </div>
      </div>
    </div>
    <div class="futter-box">
      <div class="row">
        <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
          <div class="bild-box"><img src="@/assets/img/img-content/sonderprodukte/futter-v3.jpg" alt="Was ist besonders nice an Futter?" class="img-fluid shadow" /></div>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <h3>Was ist besonders nice an Futter?</h3>
          <ul class="content-ul">
            <li>Sprachrohr für und an Millennials, Gen Z’s und Gen Y’s</li>
            <li>Relevante und zeitgemäß aufbereitete Inhalte</li>
            <li>10,7k Follower auf Instagram (Okt 2020)</li>
            <li>47,8k Abonnenten auf Facebook (Okt 2020)</li>
            <li>Ca. 300k Visits durchschnittlich pro Monat (Quelle: Klipfolio, Visits 1-10.2020 auf futter.kleinezeitung.at)</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="logo-box">
      <div class="img-box">
        <img src="@/assets/img/img-content/sonderprodukte/futter-logo.svg" alt="Futter Logo" class="img-fluid" />
      </div>
    </div>
    <h2>Werbung bei Futter</h2>
    <p>Crossmedial? Können wir! Wir bewerben Ihr Produkt auf all unseren Kanälen – und das plattformspezifisch. Egal ob via Facebook, Instagram, auf unserer Website oder durchgeschalten in der Studenten-App STUDO – wir bringen der jungen Zielgruppe Ihre Inhalte je nach Plattform speziell aufbereitet näher.</p>
    <div class="row">
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/sonderprodukte/futter-devices.png" alt="Futter - Devices" class="img-fluid" />
      </div>
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/sonderprodukte/futter-laptop.png" alt="Futter - Laptop" class="img-fluid" />
      </div>
    </div>
    <h2>Die Werbemöglichkeiten</h2>
    <h3>Jung. Digital. Maßgeschneidert.</h3>

    <TabGroup>
      <Tab tabid="tab1" :title="'<i class=\'material-icons\'>desktop_windows</i>Futter-Story'" active>
        <div class="row inner-content">
          <div class="col-lg-6">
            <img src="@/assets/img/img-content/sonderprodukte/futter-laptop.png" alt="Futter - Laptop" class="img-fluid" />
          </div>
          <div class="col-lg-6">
            <p>Geschichten begeistern. Immer schon. Sie wecken Emotionen, werden leicht gemerkt und weitererzählt. Nutzen Sie die Kraft des Storytellings und begeistern Sie Ihre Zielgruppe mit einem gekennzeichneten Native-Advertising-Artikel auf futter.kleinezeitung.at mit unserem #Futter-Story+ Paket.</p>
            <div class="leistung">
              <strong>Leistung</strong>
              <ul class="content-ul">
                <li>Erstellung und Veröffentlichung des Artikels auf Futter.</li>
                <li>1 Facebook-Posting an der Timeline so wie eine Social-Media-Story Folie inklusive Verlinkung und Swipe-up zum Artikel.</li>
                <li>3.500 garantierte Views</li>
              </ul>
              <div class="preis">Tarif: 3.498,00</div>
            </div>
          </div>
          <div class="col-lg-12 bsp">
            <div class="bsp-headline"><span>Umsetzungsbeispiele</span></div>
            <div class="row">
              <div class="col-lg-4">
                <a href="https://futter.kleinezeitung.at/wie-du-gegen-die-aufschieberitis-ankaempfen-kannst/ " target="_blank">
                  <img src="@/assets/img/img-content/sonderprodukte/FHKtn_Thumbnail.jpg" alt="FH Kärnten" class="img-fluid" />
                </a>
              </div>
              <div class="col-lg-4">
                <a href="https://futter.kleinezeitung.at/grawesbs-ynot/ " target="_blank">
                  <img src="@/assets/img/img-content/sonderprodukte/Grawesbs_Thumbnail.jpg" alt="Grawesbs" class="img-fluid" />
                </a>
              </div>
              <div class="col-lg-4">
                <a href="https://futter.kleinezeitung.at/how-to-so-klappt-es-mit-dem-job-deiner-traeume/ " target="_blank">
                  <img src="@/assets/img/img-content/sonderprodukte/Industrie_Thumbnail.jpg" alt="Industrie" class="img-fluid" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="tab2" :title="'<i class=\'material-icons\'>thumb_up_alt</i>Social Media Story'">
        <div class="row inner-content">
          <div class="col-lg-6">
            <img src="@/assets/img/img-content/sonderprodukte/futter-handy-insta.png" alt="Futter - Handy" class="img-fluid" />
          </div>
          <div class="col-lg-6">
            <p>Sie wollen vorn dabei sein und Ihre Kommunikation dort platzieren, wo sich Ihre Zielgruppe aufhält? Dann sollten Sie sich von unseren Social-Media-Stories auf Facebook und Instagram überzeugen lassen.</p>
            <div class="leistung">
              <strong>Leistung</strong>
              <ul class="content-ul">
                <li>10 Story Folien (veröffentlicht auf Facebook und Instagram) so wie 1 Facebook- und 1 Instagram-Posting an der Timeline bzw. im Feed Posting an der Timeline bzw. im Feed</li>
                <li>3.000 garantierte Views</li>
              </ul>
              <div class="preis">Tarif: 2.987,00</div>
            </div>
          </div>
          <div class="col-lg-12 bsp">
            <div class="bsp-headline"><span>Umsetzungsbeispiel</span></div>
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-4">
                <a href="../../pdf/sonderprodukte/BestPractice_SocialMediaStory_0620.pdf" target="_blank">
                  <span>Hier geht’s zu den Umsetzungsbeispiel</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="tab3" :title="'<i class=\'material-icons\'>movie</i>Video Content'">
        <div class="row inner-content">
          <div class="col-lg-6">
            <img src="@/assets/img/img-content/sonderprodukte/futter-devices.png" alt="Futter - Devices" class="img-fluid" />
          </div>
          <div class="col-lg-6">
            <p>Bilder sprechen mehr als 1.000 Worte. Wir kreieren mit individuellem Video-Content ein Alleinstellungsmerkmal für Sie und positionieren so Ihre Marke optimal mit Inhalten, die auf die Zielgruppe zugeschnitten sind.</p>
            <div class="leistung">
              <strong>Leistung</strong>
              <ul class="content-ul">
                <li>Videokonzeption, -produktion, -schnitt und Veröffentlichung</li>
                <li>1 Facebook-Posting an der Timeline sowie eine Social-Media-Story Folie inklusive Verlinkung und Swipe-up zum Video</li>
                <li>4.500 garantierte Views</li>
              </ul>
              <div class="preis">Tarif: 4.500,00</div>
            </div>
          </div>
          <div class="col-lg-12 bsp">
            <div class="bsp-headline"><span>Umsetzungsbeispiel</span></div>
            <div class="row justify-content-center align-self-center">
              <div class="col-lg-6">
                <div class="responsive-video">
                  <video autostart="false" controls="true">
                    <source src="https://assets.smd-digital.at/tarif-klz/videos/Cineplexx_MX4D_Futter_1080p_v3.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="responsive-video">
                  <video autostart="false" controls="true">
                    <source src="https://assets.smd-digital.at/tarif-klz/videos/Grillseminar_v1.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="responsive-video">
                  <video autostart="false" controls="true">
                    <source src="https://assets.smd-digital.at/tarif-klz/videos/Ecco_Verde_Vorstellung_Feed.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="responsive-video">
                  <video autostart="false" controls="true">
                    <source src="https://assets.smd-digital.at/tarif-klz/videos/WohnenGraz_Studentenwohnung.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </TabGroup>
    <h2>Kleines Budget – große Wirkung</h2>
    <p>Auch für kleinere Unternehmen oder einfach zum Ausprobieren bieten wir kostengünstige, crossmediale Werbepakete an.</p>
    <div class="row werbepaket">
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="werbebox">
          <div class="headline"><strong>Kleines</strong> Kommunikationspaket</div>
          <p>Social-Media-Werbeleistung passend für Aktionsbewerbungen oder für die kurzfristige Verlängerung einer Kampagne</p>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="werbebox">
          <div class="headline"><strong>Mittleres</strong> Kommunikationspaket</div>
          <p>Perfekt für die Bewerbung einer Veranstaltung, Aktion, Neuerung oder wenig erklärungsbedürftiger Produkte</p>
        </div>
      </div>
      <div class="col-lg-4 d-flex align-items-stretch">
        <div class="werbebox">
          <div class="headline"><strong>Großes</strong> Kommunikationspaket</div>
          <p>Alles drin! Social Media Werbeleistung, ein Kurzartikel auf der Website sowie Bannerwerbung – um die User auf jedem unserer Kanäle zu erreichen.</p>
        </div>
      </div>
      <div class="col-xl-12 btn-pdf">
        <a href="../../pdf/sonderprodukte/Futter_Kommunikationspakete.pdf" target="_blank"><span>Mehr Informationen</span></a>
      </div>
    </div>
    <Modal></Modal>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Futter',
  components: {
    Modal,
    TabGroup,
    Tab,
  },
});
</script>
